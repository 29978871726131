import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { APICenterService } from "../../apicenter.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
@Component({
  selector: "app-demo-card",
  templateUrl: "./demo-card.component.html",
  styleUrls: ["./demo-card.component.css"],

})
export class DemoCardsComponent implements OnInit {
  lock = false;
  show = true;
  paymentResponse: any;
  headers = new HttpHeaders({ "Content-Type": "application/json" });
  constructor(public api: APICenterService, private http: HttpClient) {}
  @Output("paymentSubmit") submit: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {}
  onSubmit() {
    this.lock = true;
    this.submit.emit();
  }
  disabled() {
    if (
      this.api.demoPaymentCard.cardNumber == "" ||
      this.api.demoPaymentCard.expMonth == "" ||
      this.api.demoPaymentCard.expYear == "" ||
      this.api.demoPaymentCard.cvv == ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  formatCardNumber(event: any) {
    const input = event.target;
    input.value = input.value
      .replace(/\D/g, "")
      .replace(/(\d{4})(?=\d)/g, "$1 ");
    this.api.demoPaymentCard.cardNumber = input.value;
  }

  getClientToken = () => {
    // console.log('get token');
    return (
      this.http
        .post(
          `${this.api.address}btPin`,
          { custId: this.api.customer.cid },
          { headers: this.headers }
        )
        // tslint:disable-next-line:indent
        .pipe(
          map((response: any) => {
            // console.log(response);
            return response.token;
            // tslint:disable-next-line:indent
          })
        )
    );
  };
  onPaymentStatus(response): void {
    this.paymentResponse = response;
    // console.log(response);
    this.api.setPIN = response.sucessMsg;
  }

  createPurchase = (nonce: string, chargeAmount: number) => {
    // // console.log(this.api.clickAanlysis)
    this.api.clickValue = "submit_ Credit Card";
    this.api.clickAnalytics();
    return this.http
      .post(
        `${this.api.address}create_purchase`,
        {
          nonce: nonce,
          chargeAmount: (this.api.total + this.api.tip).toFixed(2).toString(),
          custId: this.api.customer.cid,
          ordrId: this.api.ordrID,
          name: this.api.userName,
          tip: this.api.tip,
          dropin: this.api.dropin,
          showDisclaim: this.api.userSelectionDisClaimer,
          mktOptIn: !this.api.isMktOptIn,
          editScore: this.api.editScore,
          isSaveCard: this.api.isSaveCard,
          splInst: this.api.splInstText !== null ? this.api.splInstText : "*",
          couponName: this.api.couponName,
        },
        { headers: this.headers }
      )

      .pipe(
        map((response: any) => {
          if (response.msg === "True") {
            this.api.isSuccess = true;
            this.api.isPaid = true;
            this.api.isBt = false;
            if (!this.api.quickOrder.isQuickName) {
              if (!this.api.isOnlyPayment) {
                this.api.setPin();
              }
            } else {
              this.api.setPassword();
            }
            this.api.sendordrPOS(response.orderId);
          } else {
            this.api.isFailure = true;
            this.api.isBt = false;
          }
          return response;
        })
      );
  };
}
